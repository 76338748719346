import styled  from "styled-components"

export const Header = styled.section`
  grid-column: 2 / span 6;
  grid-row: 3;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 3;
  }
  @media screen and (max-width: 63rem) {
    grid-column: 2 / span 6;
    grid-row: 4;
    margin-top: -6rem;
  }
  @media screen and (max-width: 37rem) {
    grid-column: 2 / span 6;
    grid-row: 4;
    margin-top: 1rem;
  }
`

export const HeaderPicture = styled.section`
  z-index: -1;
  margin: -25px 0;
  grid-column: 8 / span 6;
  padding-left: 3vw;
  grid-row: 3;
  display: flex;
  align-items: center;
  
  @media screen and (max-width: 90rem) {
    grid-column: 5 / span 3;
  }
  
  @media screen and (max-width: 63rem) {
    grid-column: 5 / span 3;
    grid-row: 3;
    margin-top: -4rem;
  }
  @media screen and (max-width: 37rem) {
    margin-top: -6rem;
    padding: 0;
    grid-column: 3 / span 4;
    grid-row: 3;
  }
`

export const SiteGoal = styled.section`
  grid-column: 2 / span 12;
  grid-row: 5;
  display: flex;
  align-items: center;
  margin-bottom: 23rem;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 6;
    margin-bottom: 10rem;
  }

  @media screen and (max-width: 63rem) {
    grid-row: 6;
  }
  @media screen and (max-width: 37rem) {
    margin-bottom: 5rem;
  }
`
export const Anchor = styled.section`
  grid-column: 2 / span 12;
  grid-row: 4;
  height: 23rem;
  display: flex;
  align-items: center;

  @media screen and (max-width: 90rem) {
    grid-column: 2 / span 6;
    height: 10rem;
  }

  @media screen and (max-width: 63rem) {
    grid-row: 5;
  }

  @media screen and (max-width: 37rem) {
    height: 5rem;
  }
`

export const AnchorLink = styled.button`
  font-size: 100%;
  background-color: transparent;
  border: none;
  outline: none;
`